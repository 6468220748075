<template>
  <component :is="tag">
    <div class="text-media section-inner">
      <div class="media">
        <StoryblokComponent
          v-if="blok.media?.length === 1"
          :blok="blok.media[0]"
          class="image"
        />
      </div>
      <div class="text">
        <h2 v-if="blok.title" class="size-xl">{{ blok.title }}</h2>
        <div v-if="text" class="margin-2xs-top" v-html="text" />
      </div>
    </div>
  </component>
</template>

<script setup>
const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
  tag: {
    type: String,
    required: false,
    default: "div",
  },
});

const { blok } = toRefs(props);
const text = blok?.value?.text?.content
  ? useRenderRichText(blok?.value?.text)
  : `<p>${blok?.value?.text}</p>`;
</script>

<style lang="scss" scoped>
.text-media {
  text-align: center;
  .text {
    margin-top: 2.5rem;
  }
  :deep(.asset-image) {
    width: 100%;
    border-radius: $radius-l;
    overflow: hidden;
  }
}
@include for-desktop-up {
  .text-media {
    display: flex;
    flex-direction: row;
    flex-direction: row-reverse;
  }
  .text {
    width: 50%;
    align-self: center;
    margin-top: 0;
    padding: 0 5rem;
  }
  .media {
    width: 50%;
  }
}
</style>
